
.projects {

width:100%;
display:flex;
flex-direction: column;
flex-wrap:wrap;
background-image: url(/src/assets/images/mountains.png);
background-repeat: no-repeat;
background-position: center;
background-size:cover;
padding-bottom: 400px;




}

.cardsLeft {
    width:100%;
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 50px;
    align-items:center;

}



.cardsRight{
    width:100%;
    display:flex;
    flex-direction: column;
    margin-top: 50px;
    align-items:center;
    flex-wrap: wrap;

}

@media (min-width: 640px){
    .projects {
     width:92%;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .cardsLeft {
        width:50%;
        flex-wrap: wrap;
    }
    
    
    
    .cardsRight{
        width:50%;
        flex-wrap: wrap;
    
    }
}