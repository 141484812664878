.contactMe{
    display:flex;
    width:100%;
    align-items: center;
    background-color: #FEDC97;
    justify-content: space-evenly;
 

   
}



.email {
    color: #587B7F;
   
} 


.gitHub{

  color: #587B7F
}

.linkedin {
    color: #587B7F
}





@media (min-width: 640px){
    .contactMe{
        width:8%;
        flex-direction:column;
    }
}