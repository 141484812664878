body {
  font-family: "Raleway", "Cormorant Garamond", "Noto Serif Display", "Poppins",
    "Source Sans Pro", sans-serif, serif;
}

.aboutSab {
  background-image: url(/src/assets/images/yellowblue.png);
  background-size: cover;
  background-repeat: repeat;
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100%;

}


.midSection {
  width: 100%;
  /* height:100vh; */
  display: flex;
  flex-direction: column;
  margin: 15px;
  align-items: center;
}

.introSummary {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  background-color: #7c9885;
  border-radius: 15px;
  color: #fedc97;
  font-weight: bolder;
  padding: 12px;
}

.portfolioImg {
  width: 175px;
  height: 175px;
  border-radius: 20%;
  background-color: #28666e;
  display: flex;
  padding: 8px;
  margin: 50px;
  justify-content: center;
}
.sabrina {
  border-radius: 15px;
  height: 150px;
  width: 150px;
}

.favorites {
display: flex;
flex-direction: column;
}
.dichotomy {
  display: flex;
  justify-content: center;
  background:#28666e;
  border-radius: 15px;
 height: 150px;
 width: 150px;
 padding: 2px;
 margin: 20px;
 
}

.findingMe{
  display: flex;
  justify-content: center;
  background:#28666e;
  border-radius: 15px;
 height: 150px;
 width: 150px;
 padding: 2px;
 margin: 20px;

}



.skills {
 
  background-color: #fedc97;
  border: solid 1px #fedd00;
  text-align: center;
  align-content: space-between;
  color:#28666e;
  border-radius: 25%;
  
}

.core {
  width: 100%;
  background-color: #fedc97;
  border-radius: 15px;
  text-align: left;
  padding: 15px;
  margin: 20px;
}
.mission {
  text-align: center;
  padding: 15px;
  border-radius: 15px;
  color: #033f63;
  font-weight: bolder;
  flex-direction: column;
  align-items: center;
}

.values {
  background-color: #7c9885;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fedc97;
}

@media (min-width:640px) {
  .aboutSab {
    width: 92%;
    flex-direction: row;
  }

  .midSection {
    width: 75%;
  }
  .core {
    width: 25%;
  }
  .favorites{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
}
