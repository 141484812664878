
.resumePage{
background-image: url(/src/assets/images/trees.png);
background-size: cover;
display:flex;
flex-direction: column;
align-items: center;
height:100%;


}


.quote {

    display:flex;
    flex-direction: column;
    text-align: center;
    padding:12px;
    margin:150px;
    color:#033F63;
    font-weight: bolder;
    background-color: #FEDC97;
    border-radius: 10px;

}


.pdfResume{
display:flex;
flex-direction:column;
align-items: center;
justify-content: center;
width:90%;





}


.resumeImg{
    width:90%;
    display:flex;

    justify-content: center;
}

.buttonResume {
    background-color: #033F63;
    color:#FEDC97;
    font-weight: bolder;
    display:flex;
   justify-content: center;
   align-items: center;
   border-radius: 195px;
   margin:10%;

   
}



@media (min-width:640px){
    .resumePage {
        width:92%;

    }
    .pdfResume{
        width:75%;
        margin-left: 12%;

    }

}